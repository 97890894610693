<template>
    <!-- модальное окно создания отвала -->
    <modal @close="$emit('close')">
        <template v-slot:title>{{ $t('newApplication') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <!-- <div class="mb-1">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="check">
                    <label class="form-check-label" for="flexCheckDefault" style="font-size: 16px;">
                        Відвали за оплатами
                    </label>
                </div>
                <div class="mb-3" v-if="check === true">
                    <div style="width: 300px; height: 100px; border-color: black; border-radius: 20px; overflow: auto;">
                        <p v-for="items, i in list" :key="i" style="font-size: 14px;">{{ items }}</p>
                    </div>
                </div>
            </div> -->
                <div class="mb-1">
                    <h4 class="fs-15">{{ $t('information_create_application') }}</h4>
                    <!-- <Multiselect
                        v-model="form.userPhones"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="true"
                    /> -->
                    <div class="input-group mb-3" style="margin: 0 !important">
                        <input type="text" class="form-control" :placeholder="this.$t('enterCompOrPhone')" aria-label="Username" aria-describedby="basic-addon1" v-model="form.userPhone">
                    </div>
                    <br />
                </div>
                <div class="row">
                  <!--  <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_status') }}</h4>
                        <Multiselect 
                            v-model="this.form.status"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.listStatus"
                            :placeholder="$t('Status')"
                            disabled
                        />
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('choose_a_priority') }}</h4>
                            <Multiselect 
                                v-model="this.form.priority"
                                :close-on-select="true"
                                :searchable="true"
                                :create-option="false"
                                :options="this.listPriority"
                                :placeholder="$t('priority')"
                            />
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="VertimeassageInput" class="form-label">{{ $t('Message') }}</label>
                    <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="comment" :placeholder="$t('infoFutureCall')"></textarea>
                </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="this.$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button>
            <button v-if='this.perm == false || this.perm == undefined' type="button" class="btn btn-primary" v-on:click="add">{{ $t('Add') }}</button>
            <button v-else  type="button" class="btn btn-primary" v-on:click="edit">{{ $t('Edit') }}</button>

        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal-small'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { ColdCalls } from '@/API.js';
import { storeS } from '@/store';
import dbPromise from '@/indexedDB';


let apiServe = new ColdCalls();

export default ({
    data(){
        return {
            form: {
                userPhones: '', //номер телефона заявки
                priority: "", //приоритет заявки
                status: "new", //статус заявки
                comment: '',
            },
            lastForm: '',
            check: false,
            dontSave: false,
            showmodal: false,
            comment: "",
            listStatus: [ //статус заявки
                {
                    "label": this.$t('statusNew'),
                    "value": "new"
                },
                {
                    "label": this.$t('statusrescheduled'),
                    "value": "rescheduled"
                }
            ],
            listPriority: [ //тип приоритета
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
        }
    },
    props: ["phone", 'obj', 'perm'],
    components: { 
        modal, 
        Multiselect, 
    },


    created(){
        this.comment = ''
        if(this.perm == true) {
            this.form = this.obj
            console.log(this)
            this.form.comment = this.obj.createComment ? this.obj.createComment : ''  
            if(typeof this.form.userPhone != 'object') {
                // this.form.userPhone = [this.form.userPhone]
            } 
        } else {
            
            this.form = {
                userPhones: '', //номер телефона заявки
                priority: "", //приоритет заявки
                comment: '',
            }
        }

        //перевірка на наявність не закінченних заявок
        dbPromise.getAll('cold')
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            if(status.status == true) {
                dbPromise.get(status.form.id, status.form.category) // ставимо галочку, що взяли форму, далі вона видалиться

                this.form = JSON.parse(status.form.form)
                if(status.form.text !== '') {
                    this.perm = status.form.text
                }
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        
        //вибір дії було зроблено, обробка
    },
    beforeUnmount() {
        if(this.perm) {
            sessionStorage.setItem('perm', this.perm)
        }
        if(this.dontSave == false){
            if(this.form.userPhones.length > 0 || this.form.comment != '') {
                let detail = {
                    dateTime: new Date(),
                    text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                    category: 'cold',
                    form: JSON.stringify(this.form)
                }; 

                dbPromise.add(detail) //додавання в indexedDB
            }
        }
    },
    methods: {
        // add() {
        //     this.form.comment = this.comment
            
        //     if (this.form.userPhones.length < 1) {
        //         //перевіряємо чи вказані номера
        //         this.$toast.error(this.$t('EnterNumberClient'));
        //         return
        //     }
        //     localStorage.setItem('dumpLastForm', JSON.stringify(this.form))
        //     localStorage.setItem('dumpLastFormTime', new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate())
        //     if(this.form.userPhones){
        //         this.form.userPhones = this.form.userPhones.split(' ')
        //         apiServe.addDump(this.form).then(result => {
        //             if(result.status === 'error') {
        //                 switch(result.error[0]){
        //                     case "Request already exists":
        //                     this.$toast.error(this.$t("aplic_already_yet"))
        //                 }
        //             }
        //             if(result.status === "done"){
        //                 this.$toast.success(this.$t('сreatedT'));
        //                 this.dontSave = true
        //                 this.$emit('close')
        //                 this.$emit('getData')
        //                 this.eventBus.emit('saveApplic', true)
        //                 localStorage.removeItem("check_comment"+this.form.id);
        //             }
        //         })
        //         this.comment = ""
        //     }
        // },
        edit() {
            // if(this.form.userPhone.length == 1) {
            //     this.form.userPhone = this.form.userPhone.join('')
            // }

            // if (this.form.userPhones.length < 1) {
            //     //перевіряємо чи вказані номера
            //     this.$toast.error(this.$t('EnterNumberClient'));
            //     return
            // }

            let obj = {
                userPhone:this.form.userPhone,
                priority: this.form.priority,
                comment: this.comment
            }

            apiServe.editCall(this.form.requestId, obj).then(result => {
                if(result.status === 'error') {
                    this.$toast.error(this.$t("error"))
                }
                if(result.status === "done"){
                    this.$toast.success(this.$t('alertChanged'));
                    this.dontSave = true
                    this.$emit('close')
                }
            })
        },
        savecomment() {
            if(this.comment.length < 1) {
                localStorage.removeItem("check_comment");
            }
            this.$emit('close')
        },
    },
    mounted(){
        this.comment = JSON.parse(localStorage.getItem("check_comment")) || [];

        if(localStorage.getItem('dumpLastForm')) {
            this.lastForm = JSON.parse(localStorage.getItem('dumpLastForm'))
            this.lastForm.data = localStorage.getItem('dumpLastFormTime')
        }

    },
    watch: {
        comment(newValue) {
            // if(this.checks['009']) {
                localStorage.setItem("check_comment", JSON.stringify(newValue));
            // }
        }
    },
    computed: {
        user() {
            return storeS.userbase
        },
        checks(){
            return storeS.checks
        },
    }
})
</script>
q